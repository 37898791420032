export const LIST_SERVICES_LV = {
    list_services : {
        list_header : "Praksē tiek veikti visi nepieciešamie izmeklējumi un ķirurģiskās manipulācijas",
        list_items : [
            {
                item : "radiofrekvences elektrokoagulācija deguna gliemežnīcām un mandelēm"
            },
            {
                item : "ultrasonoskopija deguna blakus dobumiem"
            },
            {
                item : "rinomanometrija (deguna elpošanas funkcijas izvērtēšanai)"
            },
            {
                item : "timpanometrija"
            },
            {
                item : "audiometrija"
            },
            {
                item : "deguna endoskopija"
            },
            {
                item : "balsenes endoskopija"
            },
            {
                item : "auss mikroskopiska izmeklēšana un / vai endoskopija, videoendoskopija"
            },
            {
                item : "deguna blakus dobumu skalošana (vakuuma atsūkšana)"
            },
            {
                item : "deguna asiņošanas apturēšana"
            },
            {
                item : "Haimora dobuma punkcija"
            },
            {
                item : "deguna dobumu, rīkles dobuma, balss saišu endoskopiska izmeklēšana"
            },
            {
                item : "mandeļu lakūnu skalošana"
            },
            {
                item : "medikamentu ievadīšana balsenē"
            },
            {
                item : "sēra korķu izņemšana un / vai skalošana"
            },
            {
                item : "paracentēze"
            },
            {
                item : "furunkuļa, abscesa atvēršana"
            },
            {
                item : "svešķermeņu izņemšana"
            },
            {
                item : "anestēzija"
            },
            {
                item : "injekcijas ādā, zemādā, muskulī"
            },
            {
                item : "injekcijas vēnā "
            },
            {
                item : "video"
            },
            {
                item : "video endoskopija balsenei un aizdegunei"
            },
            {
                item : "fibrorinofaringolaringoskopija"
            },
            {
                item : "elektrokoagulācija"
            },
            {
                item : "u.c."
            }
        ]
    }
}