export const CHAPTER_PAGES_RU = {
    chapter_home : {
        part_01 : "Высококвалифицированные и сертифицированные специалисты.\nДиагностика и лечение.\nСамые современные технологии",
        part_02 : "Современная практика врачей в центре Риги, специализирующихся на диагностике и лечении заболеваний уха, горла и носа у детей и взрослых.",
        part_03 : "В практике работают сертифицированные и опытные специалисты-оториноларингологи, которые регулярно совершенствуют свои знания на семинарах и научных конференциях в Латвии и за рубежом.",
        part_04 : "В практике все необходимые исследования проводятся с использованием самых современных методов обследования на медицинском оборудовании высокого разрешения последнего поколения, которые являются малоинвазивными и безболезненными и позволяют максимально быстро и точно диагностировать заболевание, оказывая индивидуально адаптированные и успешное лечение.",
    },
    chapter_about : {
        part_01 : "Врачебная практика в области оториноларингологии основана в 2000 году, ее руководителем и лечащим врачом является Яутрите Лиепиня. В своем более чем 35-летнем стаже работы врач специализируется на диагностике и лечении заболеваний уха, горла и носа как у взрослых, так и у детей.",
        part_02 : "В 2015 году Др. Элина Элиас-Виганте, а в 2017 г. - др. Элина Межзиле, сестра Даце Сидоровой, помогает выполнять ежедневную работу практики.",
        part_03 : "Все практикующие врачи сертифицированы, являются членами Латвийской ассоциации оториноларингологов, регулярно повышают свои знания на местных и международных конференциях и конгрессах.",
        part_04 : "В марте к команде присоединяется сертифицированный оториноларинголог Беатрисе Розенфельде.",
        part_05 : "Практика находится в договорных отношениях с Национальной службой здравоохранения.",
    },
    chapter_services : {

    },
    chapter_prices : {
        part_01 : "Для информации:",
        part_02 : "Посещение без направления или в случае свободного выбора пациента, профилактический осмотр (в том числе для детей), комиссия шофера являются платными услугами.",
        part_03 : "Категории населения, освобожденные от сооплаты пациента",
        part_04 : "Порядок организации и оплаты медицинских услуг (положение МК № 555)",
    },
    chapter_info : {
        info_list_01 : {
            part_01 : "На практике прием пациентов осуществляется в соответствии со следующей процедурой:",
            part_02 : "по предварительной записи",
            part_03 : "по направлению семейного врача или специалиста (не распространяется на платные услуги)",
            part_04 : "при предъявлении документа, удостоверяющего личность",
            part_05 : "предъявить полис медицинского страхования (если применимо)",
            part_06 : "дети должны приходить в сопровождении родителей или других законных представителей",
        },
        info_part_01 : "(законный представитель ребенка должен доказать свое правовое положение по отношению к несовершеннолетнему ребенку, родители для этого должны иметь соответствующую запись в паспорте отца или матери или свидетельстве о рождении ребенка с указанием фамилий родителей, иждивенцев лиц, в том числе бабушек и дедушек, братьев, сестер, других родственников необходимо оформить нотариальную доверенность на представление законных прав и интересов ребенка)",
        info_list_02 : {
            part_01 : "Категории населения, освобожденные от сооплаты пациента",
            part_02 : "необходимо представить подтверждающий документ",
            part_03 : "необходимо прибыть в защитной маске (можно приобрести в аптеке на 1 этаже)",
            part_04 : "outerwear must be handed over to the cloakroom on the 2nd floor",
        },
    },
    chapter_contacts : {
        part_01 : "Прибытие пациентов точно определяется по времени и с лицевой маской. Дети могут иметь одного сопровождающего.",
        part_02 : "Посещение очных или дистанционных консультаций только по предварительной записи, записаться по телефону +371 67186950 или",
        part_03 : "В настоящее время время \"платных консультаций\" недоступно",
        part_04 : "На данный момент время приема в поликлинике недоступно до",
        part_05 : "Адрес: Бривибас 68, Рига",
        part_06 : "(вход с улицы Гертруды)",
    }
}