export const LIST_SERVICES_RU = {
    list_services : {
        list_header : "В клинике проводятся все необходимые обследования и хирургические манипуляции.",
        list_items : [
            {
                item : "радиочастотная электрокоагуляция улитки носа и миндалин"
            },
            {
                item : "УЗИ околоносовых пазух"
            },
            {
                item : "риноманометрия (для оценки функции носового дыхания)"
            },
            {
                item : "тимпанометрия"
            },
            {
                item : "аудиометрия"
            },
            {
                item : "носовая эндоскопия"
            },
            {
                item : "эндоскопия гортани"
            },
            {
                item : "микроскопическое исследование уха и/или эндоскопия, видеоэндоскопия"
            },
            {
                item : "Промывание околоносовых пазух (вакуумный отсос)"
            },
            {
                item : "остановка кровотечения из носа"
            },
            {
                item : "Пункция полости Хаймора"
            },
            {
                item : "эндоскопическое исследование носовых полостей, глотки, голосовых связок"
            },
            {
                item : "промывание лакун миндалин"
            },
            {
                item : "введение лекарства в гортань"
            },
            {
                item : "удаление и/или промывка серных пробок"
            },
            {
                item : "парацентез"
            },
            {
                item : "вскрытие фурункула, абсцесса"
            },
            {
                item : "удаление инородных тел"
            },
            {
                item : "анестезия"
            },
            {
                item : "инъекции в кожу, подкожную клетчатку, мышцы"
            },
            {
                item : "внутривенная инъекция"
            },
            {
                item : "видео"
            },
            {
                item : "видеоэндоскопия гортани и воспаления"
            },
            {
                item : "фиброринофаринголарингоскопия"
            },
            {
                item : "электрокоагуляция"
            },
            {
                item : "и т.п."
            }
        ]
    }
}
