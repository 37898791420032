export const LIST_PRICES_RU = {
    list_prices_with_reference : {
        list_header : "По направлению семейного врача:",
        list_items : [
            {
                id : "1",
                item : "Для детей",
                price : "0"
            },
            {
                id : "2",
                item : "Для взрослых - плата за пациента",
                price : "4"
            },
            {
                id : "3",
                item : "Плата за лечение взрослых в дополнение к УЗИ носовых пазух",
                price : "4"
            },
            {
                id : "4",
                item : "Плата за взрослого пациента в дополнение к эндоскопическим исследованиям",
                price : "7"
            },
            {
                id : "5",
                item : "Вклад пациента для взрослых в дополнение к хирургическим манипуляциям",
                price : "4"
            },
            {
                id : "6",
                item : "Выписка по желанию пациента",
                price : "5"
            }
        ]
    },
    list_prices_without_reference : {
        list_header : "Без направления семейного врача:",
        list_items : [
            {
                id : "1",
                item : "Первый прием у врача",
                price : "45"
            },
            {
                id : "2",
                item : "Пересмотрено",
                price : "35"
            },
            {
                id : "3",
                item : "Промывание носа (вакуумный отсос)",
                price : "10"
            },
            {
                id : "4",
                item : "Остановить носовое кровотечение с помощью передней тампонады или нитрата серебра.",
                price : "10"
            },
            {
                id : "5",
                item : "Электрокоагуляция",
                price : "15"
            },
            {
                id : "6",
                item : "Пункция полости Хаймора",
                price : "15"
            },
            {
                id : "7",
                item : "Удаление инородных тел из носа, горла или уха",
                price : "15"
            },
            {
                id : "8",
                item : "Анестезия",
                price : "7"
            },
            {
                id : "9",
                item : "Взятие биопсии",
                price : "18"
            },
            {
                id : "10",
                item : "Вскрытие паратонзиллярного абсцесса",
                price : "16"
            },
            {
                id : "11",
                item : "Вскрытие абсцесса",
                price : "12"
            },
            {
                id : "12",
                item : "Промывание лакунарных миндалин",
                price : "14"
            },
            {
                id : "13",
                item : "Введение лекарств в гортань или наружный слуховой проход, нос или анемизация слизистой оболочки",
                price : "7"
            },
            {
                id : "14",
                item : "Удаление и/или промывка серных пробок",
                price : "10"
            },
            {
                id : "15",
                item : "парацентез",
                price : "15"
            },
            {
                id : "16",
                item : "Вскрытие фурункула, абсцесса",
                price : "15"
            },
            {
                id : "17",
                item : "Фиброринофаринголарингоскопия",
                price : "20"
            },
            {
                id : "18",
                item : "прорыв евстахиевой трубы",
                price : "6"
            },
            {
                id : "19",
                item : "Массаж барабанной перепонки",
                price : "7"
            },
            {
                id : "20",
                item : "Проверка слуха камертонами",
                price : "5"
            },
            {
                id : "21",
                item : "Снятие швов и/или удаление тампонов после операции",
                price : "14"
            },
            {
                id : "22",
                item : "Промывание носовых полостей/пазух, прием медикаментов в послеоперационном периоде",
                price : "10"
            },
            {
                id : "23",
                item : "Риноманометрия 4 фаз до и после анемизации",
                price : "20"
            },
            {
                id : "24",
                item : "Инъекции в кожу, подкожную клетчатку, мышцу",
                price : "3"
            },
            {
                id : "25",
                item : "Инъекция в вену",
                price : "7"
            },
            {
                id : "26",
                item : "Взятие крови из вены",
                price : "5"
            },
            {
                id : "27",
                item : "Внутривенная система",
                price : "14"
            },
            {
                id : "28",
                item : "Радиочастотная электрокоагуляция носовой улитки",
                price : "175"
            },
            {
                id : "29",
                item : "Радиочастотная электрокоагуляция миндалин",
                price : "175"
            },
            {
                id : "30",
                item : "Тимпанометрия с измерением импеданса",
                price : "12"
            },
            {
                id : "31",
                item : "Обнаружение рефлекса",
                price : "4"
            },
            {
                id : "32",
                item : "Осмотр барабанной перепонки и/или барабанной полости с помощью бинокулярного микроскопа",
                price : "10"
            },
            {
                id : "33",
                item : "Аудиометрия",
                price : "18"
            },
            {
                id : "34",
                item : "Эндоскопическое исследование полости носа, глотки, голосовых связок, барабанной перепонки, в том числе видео",
                price : "15"
            },
            {
                id : "35",
                item : "УЗИ придаточных пазух носа",
                price : "7"
            }
        ]
    }
}
