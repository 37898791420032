export const LIST_SERVICES_EN = {
    list_services : {
        list_header : "All necessary examinations and surgical manipulations are performed in the practice",
        list_items : [
            {
                item : "radiofrequency electrocoagulation of the nasal cochlea and tonsils"
            },
            {
                item : "ultrasonoscopy of the paranasal sinuses"
            },
            {
                item : "rhinomanometry (to assess nasal breathing function)"
            },
            {
                item : "tympanometry"
            },
            {
                item : "audiometry"
            },
            {
                item : "nasal endoscopy"
            },
            {
                item : "laryngeal endoscopy"
            },
            {
                item : "ear microscopic examination and / or endoscopy, videoendoscopy"
            },
            {
                item : "rinsing of paranasal sinuses (vacuum suction)"
            },
            {
                item : "stopping nosebleeds"
            },
            {
                item : "Highmore cavity puncture"
            },
            {
                item : "endoscopic examination of nasal cavities, pharynx, vocal cords"
            },
            {
                item : "rinsing of tonsil lacunae"
            },
            {
                item : "administration of medication into the larynx"
            },
            {
                item : "removal and / or flushing of sulfur plugs"
            },
            {
                item : "paracentesis"
            },
            {
                item : "opening of a boil, abscess"
            },
            {
                item : "removal of foreign bodies"
            },
            {
                item : "anesthesia"
            },
            {
                item : "injections into the skin, subcutaneous tissue, muscle"
            },
            {
                item : "intravenous injection"
            },
            {
                item : "video"
            },
            {
                item : "video endoscopy of the larynx and inflammation"
            },
            {
                item : "fibrorhinopharyngolaryngoscopy"
            },
            {
                item : "electrocoagulation"
            },
            {
                item : "etc."
            }
        ]
    }
}